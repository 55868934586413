<template>
    <div class="aops-basic-attribute">
        <div class="aop-attribute-title">基础属性</div>
        <el-form
            :inline="true"
            label-position="left"
            label-width="80px"
            :model="assetForm"
            :disabled="true"
        >
            <div class="aop-attribute-list">
                <div
                    v-for="(item, index) in basicAttribute"
                    :key="index"
                    class="aop-attribute-item"
                >
                    <el-form-item :label="item.label" class="aop-attribute-input">
                        <el-input
                            v-model="assetForm[item.key]"
                            :type="item.type"
                        />
                    </el-form-item>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
import { basicAttribute } from '../../attributeData';

export default {
    props: {
        assetForm: {
            type: Object,
            /**
             * assetForm默认值
             * @return {object}
            */
            default() {
                return {};
            }
        }
    },
    /**
     * data
     * @returns {Object}
    */
    data() {
        return {
            basicAttribute
        };
    }
};
</script>

<style lang="less">
@import "./basicAttribute.less";
</style>
