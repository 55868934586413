<template>
    <div class="aops-warseHouse-detail">
        <detail-left
            :asset-img="assetImg"
            :is-move="is_move"
            :name="name"
        />
        <div class="aop-warseHouse-detail-right">
            <basic-attribute :asset-form="assetForm" />
            <downloadAttribute :asset-form="assetForm" />
            <div class="aop-warseHouse-detail-btn">
                <el-button
                    v-if="is_move === 0"
                    @click="handleMove"
                >
                    <img class="aop-btn-icon" src="../move.png" alt="icon" />移入
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import Api from '@/api/assets';
import detailLeft from './components/detailLeft/detailLeft';
import basicAttribute from './components/basicAttribute/basicAttribute.vue';
import downloadAttribute from './components/downloadAttribute/downloadAttribute.vue';

export default {
    components: { detailLeft, basicAttribute, downloadAttribute },
    /**
     * data
     * @returns {Object}
    */
    data() {
        return {
            assetImg: '',
            assetForm: {},
            is_move: null, // 0：未移入 1：已移入
            name: '' // 资产名称
        };
    },
    /**
     * mounted
    */
    mounted() {
        this.init();
    },
    methods: {
        /**
         * 获取资产详情
        */
        init() {
            const info = {
                id: this.$route.query.id // 'MDCNR3UE4Z01'
            };
            Api.assetCentreInfo(info).then((res) => {
                if (res.data.code === 10200) {
                    const data = res.data.data;
                    this.assetImg = data.preview_url;
                    this.is_move = data.is_move;
                    this.name = data.name;
                    this.assetForm = {
                        ...data,
                        specular: data.specular
                            ? data.specular.toString()
                            : '',
                        scatter: data.scatter
                            ? data.scatter.toString()
                            : '',
                        fuzz: data.fuzz
                            ? data.fuzz.toString()
                            : '',
                        meshFormat: data.mesh_format
                            ? data.mesh_format.toString()
                            : '',
                        materialPreset: data.material_preset
                            ? data.material_preset.toString()
                            : ''
                    };
                } else {
                    this.$$error(res.data.message);
                }
            });
        },
        /**
         * 移入
        */
        handleMove() {
            const data = {
                id: this.assetForm.id
            };
            Api.moveAsset(data).then((res) => {
                if (res.data.code === 10200) {
                    this.$$success('操作成功');
                    this.$router.push('/assets/warehouse');
                } else {
                    this.$$error(res.data.message);
                }
            });
        }
    }
};
</script>

<style lang="less">
@import "./detail.less";
</style>
