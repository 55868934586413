export const basicAttribute = [{
    label: '内部ID',
    key: 'id',
    type: 'input'
}, {
    label: '资产类型',
    key: 'type',
    type: 'input'
}, {
    label: '资产名称',
    key: 'name',
    type: 'input'
}, {
    label: '资产大小',
    key: 'size',
    type: 'input'
}, {
    label: '资产描述',
    key: 'description',
    type: 'textarea'
}];
export const downloadAttribute = [{
    label: 'Material Preset',
    key: 'materialPreset',
    type: 'input'
}, {
    label: 'Normal',
    key: 'normalValue',
    type: 'select',
    option: 'normal'
}, {
    label: 'Albedo',
    key: 'albedoValue',
    type: 'select',
    option: 'albedo'
}, {
    label: 'Metallic',
    key: 'metallicValue',
    type: 'select',
    option: 'metallic'
}, {
    label: 'Roughness',
    key: 'roughnessValue',
    type: 'select',
    option: 'roughness'
}, {
    label: 'AO',
    key: 'aoValue',
    type: 'select',
    option: 'ao'
}, {
    label: 'Displacement',
    key: 'displacementValue',
    type: 'select',
    option: 'displacement'
}, {
    label: 'Gloss',
    key: 'glossValue',
    type: 'select',
    option: 'gloss'
}, {
    label: 'Specular',
    key: 'specular',
    type: 'input'
}, {
    label: 'Scatter',
    key: 'scatter',
    type: 'input'
}, {
    label: 'Cavity',
    key: 'cavityValue',
    type: 'select',
    option: 'cavity'
}, {
    label: 'Fuzz',
    key: 'fuzz',
    type: 'input'
}];
