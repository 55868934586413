<template>
    <div class="aops-download-Attribute">
        <el-form
            :inline="true"
            label-position="left"
            label-width="125px"
            :model="assetForm"
        >
            <div class="aop-download-header">
                <div class="aop-attribute-title">下载属性</div>
                <div class="aop-header-attribute">
                    <el-form-item label="Resolution">
                        <el-checkbox
                            v-for="(item, index) in assetForm.texture_resolution"
                            :key="index"
                            v-model="checked"
                            disabled
                        >{{ item }}</el-checkbox>
                    </el-form-item>
                </div>
                <div class="aop-header-attribute">
                    <el-form-item label="Mesh Format">
                        <el-input v-model="assetForm.meshFormat" disabled></el-input>
                    </el-form-item>
                </div>
                <div class="aop-model-levels">
                    <div
                        v-for="(item, index) in modelLevels"
                        :key="index"
                        class="aop-model-levels-item"
                    >
                        <el-checkbox
                            v-for="(v, i) in item"
                            :key="i"
                            v-model="checked"
                            disabled
                            class="aop-model-levels-checkbox"
                        >{{ v }}</el-checkbox>
                    </div>
                </div>
            </div>
            <div class="aop-attribute-list">
                <div
                    v-for="(item, index) in downloadAttribute"
                    :key="index"
                    class="aop-attribute-item"
                >
                    <el-form-item :label="item.label">
                        <el-input
                            v-if="item.type ==='input'"
                            v-model="assetForm[item.key]"
                            disabled
                        ></el-input>
                        <el-select
                            v-if="item.type ==='select'"
                            v-model="assetForm[item.key]"
                            placeholder="PNG"
                        >
                            <el-option
                              v-for="(v, i) in assetForm[item.option]"
                              :key="i"
                              :label="v"
                              :value="v"
                              disabled
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
import { downloadAttribute } from '../../attributeData';

export default {
    props: {
        checked: {
            type: Boolean,
            default: true
        },
        assetForm: {
            type: Object,
            /**
             * assetForm默认值
             * @return {object}
            */
            default() {
                return {};
            }
        }
    },
    /**
     * data
     * @returns {Object}
    */
    data() {
        return {
            downloadAttribute
        };
    },
    computed: {
        /**
         * modelLevels
         * @returns {Array}
        */
        modelLevels() {
            const arr = this.assetForm.model_levels || [];
            return [arr.slice(0, 4), arr.slice(4, 8)];
        }
    }
};
</script>

<style lang="less">
@import "./downloadAttribute.less";
</style>
