<template>
    <div class="aops-warseHouse-detail-left">
        <div
            class="aop-detail-left-back"
            @click="handleBack"
        >
            <i class="iconfont aop-detail-left-back-icon">&#xe647;</i>返回
        </div>
        <div class="aop-detail-left-title">
            <span class="aop-assets-name">{{name}}</span>
            <span class="aop-assets-status">{{statusText}}</span>
        </div>
        <div class="aop-assets-img-wrap">
            <img
                class="aop-assets-img"
                :src="assetImg"
                alt="资产图"
            />
        </div>
    </div>
</template>

<script>

export default {
    props: {
        assetImg: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        isMove: {
            type: Number,
            default: null
        }
    },
    computed: {
        /**
         * 移入状态
         * @returns {string}
        */
        statusText() {
            return this.isMove === null ? '' : this.isMove ? '已移入' : '未移入';
        }
    },
    methods: {
        /**
         * 返回资产库页面
        */
        handleBack() {
            // 跳转资产库页面，更新资产库列表
            this.$router.push('/assets/warehouse');
        }
    }
};
</script>

<style lang="less">
@import "./detailLeft.less";
</style>
